var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AView',{ref:"deliverable-editor-view",attrs:{"value":_vm.value,"actions":_vm.actions,"active":_vm.deliverable.id === _vm.activeDeliverableId,"name":_vm.deliverable.name,"payload":{
    from: _vm.value,
    deliverable: _vm.deliverable,
  }},on:{"onHover":_vm.onHover,"onActivate":(e) => _vm.$emit('onActivate', e),"onMaximize":(e) => _vm.$emit('onMaximize', e),"onExpand":(e) => _vm.$emit('onExpand', e),"onCollapse":(e) => _vm.$emit('onCollapse', e),"onClose":(e) => _vm.$emit('onClose', e)},scopedSlots:_vm._u([{key:"content",fn:function({}){return [(_vm.deliverable.id)?_c('DeliverableDetailsVue',{attrs:{"value":_vm.deliverable,"permissions":_vm.value.permissions,"payload":{
        from: _vm.value,
        deliverable: _vm.deliverable,
      }}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }