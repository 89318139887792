<template>
  <v-card class="transparent ma-0 pa-2 elevation-0">
    <v-card-title>
      <v-icon class="pa-2 mr-4">{{ value.icon }}</v-icon>
      {{ String(value.name).toUpperCase() }}
      <v-spacer></v-spacer>
      <span v-if="value.activity">
        <v-icon color="primary" class="pa-2 mb-1">mdi-clock-outline</v-icon>
        {{ durationFormatter(value.activity.avg_time, "m") }}</span
      >
      <span v-if="value.document">
        <v-icon color="primary" class="pa-2 mb-1">mdi-clock-outline</v-icon>
        {{ durationFormatter(value.document.avg_time, "m") }}</span
      >
    </v-card-title>
    <v-divider class="ma-4"></v-divider>

    <v-card-text class="">
      <v-slide-group
        v-model="tagIndex"
        class="my-4"
        center-active
        show-arrows
        v-if="value.tags && value.tags.length"
      >
        <v-slide-item
          v-for="item in value.tags"
          :key="item.tag.id"
          v-slot="{ toggle }"
        >
          <v-chip
            class="ma-2"
            :color="item.tag.color"
            @click="toggle"
            label
            text-color="white"
          >
            <v-icon v-if="item.tag.icon" left> {{ item.tag.icon }} </v-icon>
            {{ item.tag.name }}
          </v-chip>
        </v-slide-item>
      </v-slide-group>
      <h3 class="my-2">DEFINITION:</h3>
      <p class="simple-text" v-html="value.definition"></p>

      <h3 class="my-2">GOAL:</h3>
      <p class="simple-text" v-html="value.goal"></p>

      <h3 class="my-2">LEGAL DESCRIPTION:</h3>
      <p class="simple-text" v-html="value.legal_description"></p>

      <v-alert prominent type="info" color="blue-grey" v-if="value.activity">
        <v-row align="center">
          <v-col class="grow">
            This deliverable is <strong>activity</strong>. This means that
            processing this delivery we should provide some set of activities.
            For example meetings or workshops.
          </v-col>
        </v-row>
      </v-alert>
      <v-alert prominent type="info" color="blue-grey" v-if="value.document">
        <v-row align="center">
          <v-col class="grow">
            This deliverable is <strong>document</strong>. This means that as
            the result we should deliver a document in accordance to examples
            provided below.
          </v-col>
        </v-row>
      </v-alert>

      <!-- Files -->
      <FormSection
        underline
        right
        class="mt-0"
        :label="'Examples'"
        :icon="'mdi-link-variant'"
        :actions="filesActions"
        :editable="!!filesActions.length"
        @onFilesEdit="(act) => $emit(act.on, act)"
        :payload="payload"
      ></FormSection>
      <v-alert
        class="my-2"
        dense
        outlined
        type="success"
        v-if="value.publicMaterials && value.publicMaterials.length"
      >
        Files below are <strong>PUBLIC</strong> and can be shared with a
        customer without any additional procedures.
      </v-alert>
      <v-row
        class="pa-0 ma-0"
        v-if="value.publicMaterials && value.publicMaterials.length"
      >
        <v-col cols="12">
          <div class="theme-default">
            <div class="vue-file-agent grid-block-wrapper" style="padding: 0">
              <VueFilePreview
                v-for="(fileRecord, i) in value.publicMaterials"
                :key="i"
                :value="fileRecord"
                :linkable="true"
                class="file-preview-wrapper grid-box-item grid-block"
              ></VueFilePreview>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-alert
        class="my-2"
        dense
        outlined
        type="warning"
        v-if="
          showCustomerFiles &&
          value.customerMaterials &&
          value.customerMaterials.length
        "
      >
        Files below can be shared with a customer
        <strong> ONLY after NDA signing</strong>. Please make sure that NDA
        exists.
      </v-alert>
      <v-row
        class="pa-0 ma-0"
        v-if="
          showCustomerFiles &&
          value.customerMaterials &&
          value.customerMaterials.length
        "
      >
        <v-col cols="12">
          <div class="theme-default">
            <div class="vue-file-agent grid-block-wrapper" style="padding: 0">
              <VueFilePreview
                v-for="(fileRecord, i) in value.customerMaterials"
                :key="i"
                :value="fileRecord"
                :linkable="true"
                class="file-preview-wrapper grid-box-item grid-block"
              ></VueFilePreview>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-alert
        class="my-2"
        dense
        outlined
        type="error"
        v-if="
          showPrivateFiles &&
          value.privateMaterials &&
          value.privateMaterials.length
        "
      >
        Files below are
        <strong>FULLY PRIVATE</strong> and are protected by ADAAS IP. These
        files for internal usage <strong>ONLY</strong>.
      </v-alert>

      <v-row
        class="pa-0 ma-0"
        v-if="
          showPrivateFiles &&
          value.privateMaterials &&
          value.privateMaterials.length
        "
      >
        <v-col cols="12">
          <div class="theme-default">
            <div class="vue-file-agent grid-block-wrapper" style="padding: 0">
              <VueFilePreview
                v-for="(fileRecord, i) in value.privateMaterials"
                :key="i"
                :value="fileRecord"
                :linkable="true"
                class="file-preview-wrapper grid-box-item grid-block"
              ></VueFilePreview>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
      
      
  <script>
import { DELIVERABLE_BUTTONS } from "../../../../views/src/apps/service-management/constants/actions.gh";
import { DELIVERABLE_PERMISSIONS } from "../../../../views/src/apps/service-management/constants/permissions.gh";
import { FormatterHelper } from "../../../helpers/formatter.helper";
import { PermissionsHelper } from "../../../helpers/permissions.helper";
import FormSection from "../../atoms/common/FormSection.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    permissions: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    FormSection,
  },
  data: () => ({
    tagIndex: null,
    durationFormatter: FormatterHelper.timeDuration,
  }),
  computed: {
    showCustomerFiles: function () {
      return this.permissions[
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_CUSTOMER_DOCUMENTS
      ];
    },
    showPrivateFiles: function () {
      return this.permissions[
        DELIVERABLE_PERMISSIONS.ALLOW_SEE_DELIVERABLE_PRIVATE_DOCUMENTS
      ];
    },
    filesActions: function () {
      return PermissionsHelper.getActions(
        [DELIVERABLE_BUTTONS.FILES_EDITOR],
        this.permissions
      );
    },
  },
  async created() {},
  methods: {},
  watch: {},
};
</script>