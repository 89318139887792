<template>
  <AView
    :value="value"
    :actions="actions"
    :active="deliverable.id === activeDeliverableId"
    :name="deliverable.name"
    ref="deliverable-editor-view"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
    :payload="{
      from: value,
      deliverable,
    }"
  >
    <template v-slot:content="{}">
      <DeliverableDetailsVue
        v-if="deliverable.id"
        :value="deliverable"
        :permissions="value.permissions"
        :payload="{
          from: value,
          deliverable,
        }"
      ></DeliverableDetailsVue>
    </template>
  </AView>
</template>
  
  
  <script>
import { mapGetters, mapState } from "vuex";
import DeliverableDetailsVue from "../../../../../../components/wad/organisms/deliverables/DeliverableDetails.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import { DELIVERABLE_PERMISSIONS } from "../../constants/permissions.gh";
import { DELIVERABLE_BUTTONS } from "../../constants/actions.gh";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    DeliverableDetailsVue,
  },
  data: () => ({
    DELIVERABLE_PERMISSIONS,
    tagIndex: null,
    deliverable: {},
  }),
  computed: {
    ...mapState("DeliverableStore", [
      "activeDeliverableId",
      "displayedDeliverables",
    ]),
    ...mapGetters("DeliverableStore", ["deliverableById"]),
    actions: function () {
      return PermissionsHelper.getActions(
        [
          DELIVERABLE_BUTTONS.FILES_EDITOR_MAIN,
          DELIVERABLE_BUTTONS.GENERAL_EDITOR_MAIN,
        ],
        this.value.permissions
      );
    },
  },
  async created() {
    await this.$store.dispatch("DeliverableStore/GetDeliverable", {
      id: this.value.relation.params.deliverableId,
    });

    this.deliverable = this.deliverableById(
      this.value.relation.params.deliverableId
    );
  },
  methods: {
    onHover() {
      this.$store.commit(
        "DeliverableStore/setActiveId",
        this.value.relation.params.deliverableId
      );
    },
  },
  watch: {
    displayedDeliverables: {
      async handler(newVal) {
        if (newVal) {
          this.deliverable = this.deliverableById(
            this.value.relation.params.deliverableId
          );
        }
      },
      deep: true,
    },
  },
};
</script>